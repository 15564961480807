import { render, staticRenderFns } from "./videoChat.vue?vue&type=template&id=bae55b98&scoped=true&"
import script from "./videoChat.vue?vue&type=script&lang=js&"
export * from "./videoChat.vue?vue&type=script&lang=js&"
import style0 from "./videoChat.vue?vue&type=style&index=0&id=bae55b98&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bae55b98",
  null
  
)

export default component.exports