<template>
  <div class="textChatComponents">
    <div class="closeheader">
      <el-button type="text" @click="hideChatPanel"
        ><i class="el-icon-close"></i
      ></el-button>
    </div>
    <div class="chatInner" id="chatInner" v-loading="historyMsgLoading">
      <div class="chatItemContent">
        <div
          class="item clearfix"
          v-for="(item, index) in textMsgList"
          :key="index"
          :class="{ other: item.other, self: item.self }"
        >
          <div class="avatar">
            <img :src="getInfo(item.id).avatar || DEFAULT_AVATARSQUARE" alt />
          </div>
          <div class="clearfix">
            <div class="itemName">{{ getInfo(item.id).name }}</div>
            <div class="textInner">
              <div class="square"></div>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="editText" v-show="showInput">
      <el-input
        type="textarea"
        :autosize="{ maxRows: 3, minRows: 3 }"
        :placeholder="$t('enterHere')"
        v-model="messageText"
        @keydown.enter.native="sendMessage($event)"
      >
      </el-input>
      <div class="sendBtn clearfix">
        <el-button
          type="primary"
          class="fr"
          @click="sendMessage"
          size="mini"
          plain
          >{{ $t("send") }}</el-button
        >
      </div>
      <!-- <el-input
        type="input"
        v-model="messageText"
        :placeholder="$t('enterHere')"
        resize="none"
        @keyup.enter.native="sendMessage"
      >
        <el-button slot="append" type="primary" @click="sendMessage">{{
          $t("send")
        }}</el-button>
      </el-input> -->
    </div>
  </div>
</template>

<script>
import basicTextChat from "~/basePlugins/basicTextChat";
export default {
  props: {
    chatBothInfo: {
      type: Object,
      default: () => {
        return {
          selfInfo: {},
          otherInfo: {},
        };
      },
    },
    channel_id: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: null,
    },
    chat_class: {
      type: String,
      default: "",
    },
    chatInfoList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      textChatSDK: null,
      textChatConfig: {
        user_id: null,
        channel_id: null,
        getHistory: true,
      },
      historyMsgLoading: false,
      textMsgList: [],
      messageText: "",
      showInput: true,
    };
  },
  mounted() {
    //this.initTextChat();
  },
  methods: {
    initTextChat() {
      this.textChatConfig.user_id = this.user_id;
      this.textChatConfig.channel_id = this.channel_id;
      this.textChatConfig.getHistory = false;
      this.textChatSDK = new basicTextChat(
        this.textChatConfig,
        this.$store,
        this.$message,
        {
          reviceMessage: this.reviceMessage,
          sendMessageComplete: this.sendMessageComplete,
          historyMessageComplete: this.historyMessageComplete,
        }
      );
    },
    hideChatPanel() {
      this.$emit("hideChatPanel");
    },
    reviceMessage(msg, send_id) {
      this.$emit("reviceMessage");
      this.addChatItem(msg, false, send_id);
    },
    sendMessage(e) {
      if (e.ctrlKey) {
        this.messageText = this.messageText + "\n";
      } else {
        e.preventDefault(); // 阻止浏览器默认换行操作
        if (this.messageText.trim() == "") {
          return;
        }
        this.textChatSDK.sendRTMMessage(this.messageText);
      }
    },
    sendMessageComplete(text) {
      this.addChatItem(text, true, this.user_id);
      this.messageText = "";
    },
    historyMessageComplete(list) {
      this.textMsgList = list;
      this.handleScrollHeight();
    },
    addChatItem(text, isSelf, user_id) {
      this.textMsgList.push({
        text,
        self: isSelf,
        other: !isSelf,
        id: user_id,
      });
      this.handleScrollHeight();
    },
    handleScrollHeight() {
      this.$nextTick(() => {
        let div = document.getElementById("chatInner");
        div.scrollTop = div.scrollHeight;
      });
    },
    clearTextMsgList() {
      this.textMsgList = [];
    },
    closeSdk() {
      this.showInput = false;
      this.textChatSDK.quitSdk();
    },
  },
  computed: {
    getInfo() {
      return (id) => {
        let obj =
          this.chatInfoList.find((item) => {
            console.log(this.chatInfoList)
            return item.id == id;
          }) || {};
        return obj;
      };
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  .textChatComponents {
    width: 100%;
    height: 100%;
    background: #f6f6f6;
  }
  .closeheader {
    height: 30px;
    background: #fff;

    line-height: 30px;
    text-align: right;
    padding: 0px 5px;
    cursor: pointer;
    .el-button {
      padding: 0px;
      font-size: 22px;
      position: relative;
      top: 4px;
    }
  }
  .chatInner {
    padding: 10px 10px 0px;
    height: calc(100% - 147px);
    // background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/chat_bg.png")
    //   no-repeat;
    // background-size: 100%;
    // background-position: 0 50%;
    background: #f6f6f6;
    overflow-y: auto;

    .chatItemContent {
      background: #f6f6f6;
      // height: 100%;
      .item {
        margin-bottom: 8px;

        .avatar {
          width: 32px;
          height: 32px;
          // border-radius: 50%;

          img {
            width: 32px;
            height: 32px;
            // border-radius: 50%;
          }
        }

        .textInner {
          position: relative;
          max-width: calc(100% - 76px);
          min-height: 32px;
          background: #fff;
          margin-left: 8px;
          font-size: 14px;
          display: flex;
          //border-radius: 4px;
          align-items: center;

          p {
            padding: 4px 6px;
            word-break: break-word;
          }
        }

        &.other {
          .avatar {
            float: left;
          }

          .name {
            text-align: left;
          }
          .itemName {
            font-size: 12px;
            margin-bottom: 3px;
            margin-left: 40px;
            color: #999;
          }
          .textInner {
            float: left;

            p {
              word-break: break-word;
            }
          }

          .square {
            position: absolute;
            top: 8px;
            left: -12px;
            border: 6px solid;
            width: 0px;
            height: 0px;
            border-color: #fff;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
          }
        }

        &.self {
          .avatar {
            float: right;
          }

          .name {
            text-align: right;
          }
          .itemName {
            display: none;
          }
          .textInner {
            margin-left: 0px;
            float: right;
            margin-right: 8px;
            background: #409eff;

            p {
              color: #fff;
            }

            .square {
              position: absolute;
              top: 8px;
              right: -12px;
              border: 8px solid;
              width: 0px;
              height: 0px;
              border-color: #409eff;
              border-right-color: transparent;
              border-top-color: transparent;
              border-bottom-color: transparent;
            }
          }
        }
      }
    }

    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  .editText {
    width: 100%;
    padding: 5px 0;
    margin: 0 auto;
    background: #fff;
    border-top: 1px solid #ececec;
    .sendBtn {
      padding: 0 15px 5px;
    }
    /deep/ .el-textarea__inner {
      border: none;
      resize: none;
    }
  }
}
@media screen and (max-width: 780px) {
  // @import "./less/textChat_mobile.less";
}
</style>