<template>
  <div class="videoChatComponents-multi" :class="{ atRight: smallPanel }">
    <div class="videoItemContent" :class="'v' + domsList.length">
      <div class="video-item" v-for="item in domsList" :key="item.domId">
        <div class="video_inner" :id="item.domId"></div>
        <div class="no_video_content" v-if="item.online && !item.video">
          <div class="inner">
            <img
              :src="getInfo(item.domId).avatar || DEFAULT_AVATAR"
              alt=""
              class="img"
            />
            <p>{{ $t("cameraClosed") }}</p>
          </div>
        </div>
        <div class="item_name textOverflow">
          <img
            v-if="item.audio"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/micOpen.png"
            alt=""
          />
          <img
            v-else
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/micClose.png"
            alt=""
          />
          <span v-if="item.user_id == user_id"> {{ $t("me") }} </span>
          <span v-else>{{ getInfo(item.domId).name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicChat from "~/basePlugins/basicVideoChat";
export default {
  props: {
    channel_id: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: 52049,
    },
    mediaType: {
      type: Object,
      default: () => {
        return {
          video: true,
          audio: true,
        };
      },
    },
    smallPanel: {
      type: Boolean,
      default: false,
    },
    chatInfoList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      videoChatConfig: {
        channel_id: "1594878083000",
        user_id: null,
        type: "multi",
        auto_publish: true,
        mediaConfig: {
          video: true,
          audio: true,
        },
        doms: [],
        apis: {},
      },
      domsList: [],
      videoInit: false,
    };
  },
  mounted() {
    // this.initVideoChat();
  },
  methods: {
    async initVideoChat() {
      this.videoChatConfig.channel_id = this.channel_id;
      this.videoChatConfig.user_id = this.user_id;

      this.videoChatConfig.mediaConfig = this.mediaType;
      this.domsList.push({
        domId: `window${this.videoChatConfig.user_id}`,
      });

      this.videoChatConfig.doms = this.domsList;
      this.videoChatSDK = new basicChat(
        this.videoChatConfig,
        this.$store,
        this.$message,
        {
          // joinChannelSuccess: this.joinChannelSuccess,
          userStatusChange: this.userStatusChange,
          userJoined: this.userJoined,
          userLeft: this.userLeft,
          selfVideoInit: this.selfVideoInit,
        }
      );
    },
    selfVideoInit() {
      this.videoInit = true;
    },
    userStatusChange(params) {
      let _index;
      let current = this.domsList.find((item, index) => {
        if (item.domId == `window${params.user_id}`) {
          _index = index;
          return true;
        }
      });
      if (current) {
        current = Object.assign(current, params);
        this.domsList.splice(_index, 1, current);
      }

      this.$forceUpdate();
      if (params.user_id == this.user_id) {
        this.handleSelfStatusChange();
      }
      this.handleCountChage();
    },
    async userJoined(params) {
      if (params.uid.toString().length == 8) {
        this.$store
          .dispatch("getInviteInfo", { live_id: this.channel_id })
          .then((res) => {
            if (res.success) {
              let name = res.data.find((item) => item.identify == params.uid)
                .nick_name;
              let avatar = res.data.find((item) => item.identify == params.uid)
                .avatar;
              this.$emit("userInfoUpdata", {
                id: params.uid,
                name,
                avatar
              });
            }
          });
      }
      this.domsList.push({
        domId: `window${params.uid}`,
      });
    },
    userLeft(params) {
      let _index = this.domsList.findIndex((item) => {
        return item.user_id == params.uid;
      });
      this.domsList.splice(_index, 1);
      this.$forceUpdate();
    },
    stopSelfMedia(type) {
      //audio || video
      this.videoChatSDK.closeLocaleTrack(type);
    },
    startSelfMedia(type) {
      this.videoChatSDK.createLocaleTrack(type);
    },
    handleSelfStatusChange() {
      setTimeout(() => {
        let current = this.domsList.find((item) => {
          return item.user_id == this.user_id;
        });
        this.$emit("selfStatusChange", current);
      }, 0);
    },
    handleCountChage() {
      this.$emit("countChange", this.domsList.length);
    },

    closeSdk() {
      this.videoChatSDK.quitSDK();
    },
  },
  computed: {
    getInfo() {
      return (id) => {
        let _id = id.replace("window", "");
        let obj =
          this.chatInfoList.find((item) => {
            return item.id == _id;
          }) || {};
        return obj;
      };
    },
  },
};
</script>

<style scoped lang="less">
.videoChatComponents-multi {
  width: 100%;
  height: 100%;
  .videoItemContent {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .video-item {
      position: relative;
      .video_inner {
        width: 100%;
        height: 100%;
        z-index: 9;
      }
      .no_video_content {
        background: #333;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &.initTip {
          z-index: 12;
        }
        p {
          color: #fff;
          margin-top: 5px;
        }
      }
      .item_name {
        max-width: 100px;
        min-width: 50px;
        height: 32px;
        position: absolute;
        left: 10px;
        bottom: 10px;
        background: rgba(255, 255, 255, 0.85);
        z-index: 11;
        line-height: 32px;
        border-radius: 4px;
        padding-right: 5px;
        box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 24px;
          height: 24px;
          position: relative;
          top: -1px;
        }
        span {
          font-size: 12px;
        }
      }
    }
    &.v1 {
      display: flex;
      justify-content: space-between;
      padding: 0px;
      .video-item {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
      }
    }
    &.v2 {
      display: flex;
      justify-content: space-between;
      .video-item {
        width: 49.7%;
        height: 350px;
        background: rgba(0, 0, 0, 0.8);
      }
    }
    &.v3 {
      display: flex;
      justify-content: center;
      .video-item {
        margin-right: 8px;
        width: 45%;
        height: 280px;
        background: rgba(0, 0, 0, 0.8);
      }
    }
    &.v4 {
      display: flex;
      justify-content: space-between;
      .video-item {
        width: 49.7%;
        height: 280px;
        background: rgba(0, 0, 0, 0.8);
      }
    }
    &.v5 {
      display: flex;
      justify-content: center;
      .video-item {
        width: 33%;
        height: 280px;
        margin-left: 5px;
        background: rgba(0, 0, 0, 0.8);
      }
      :nth-child(3n + 1) {
        margin-left: 0px;
      }
    }
    &.v6 {
      display: flex;
      justify-content: space-between;
      .video-item {
        width: 33%;
        height: 280px;
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
  &.atRight {
    // display: flex;
    // justify-content: flex-end;
    display: block;
    padding-left: 5px;
    .videoItemContent {
      display: block;
      width: 180px;
      padding: 0;
      .video-item {
        margin-left: 0px;
        width: 180px;
        height: 100px;
        background: rgba(0, 0, 0, 0.8);
        margin-bottom: 2px;

        .item_name {
          height: 20px;
          line-height: 20px;
          max-width: 60px;
          width: auto;
          bottom: 5px;
          left: 5px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      :last-child {
        margin-bottom: 0px;
      }
    }
  }
  .inner {
    .img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
}
</style>