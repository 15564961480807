<template>
  <div class="multiChatPage clearfix">
    <div class="meak" v-if="showCheck">
      <div class="checkDeviceComponents">
        <checkDevice ref="checkDevice" @enterInto="enterIntoChat"></checkDevice>
      </div>
    </div>
    <div class="main_content fl" v-loading="isLoading" v-if="!showCheck">
      <div class="notAtMeetingTip tc" v-if="userIdisInRoom">
        <p class="cancelTip">{{ $t("linkhasBeenLogin") }}</p>
      </div>
      <div class="notAtMeetingTip tc" v-if="isCancelMeeting">
        <p class="cancelTip">{{ $t("meetingCancel") }}</p>
      </div>

      <div
        class="notAtMeetingTip tc"
        v-if="chatStatus == 'before' || chatStatus == 'end'"
      >
        <p class="meetingTime">
          {{ $t("meetingTime") }}：{{
            talkInfo.start_time | secondFormat("LLL")
          }}
          -
          {{ talkInfo.end_time | secondFormat("T") }}
           <span v-if="0 - new Date().getTimezoneOffset() / 60 >= 0">
                        ({{
              "UTC+" + (0 - new Date().getTimezoneOffset() / 60)
            }})           </span
          >
                    <span v-else>
                        ({{
              "UTC" + (0 - new Date().getTimezoneOffset() / 60)
            }})           </span
          >
        </p>
        <p class="textTip" v-if="chatStatus == 'before'">
          {{ $t("enterMeetingBeforeTen") }}
        </p>
        <p v-if="chatStatus == 'end'" class="textTip">
          {{ $t("chatMeeingTimeEnd") }}
        </p>
      </div>
      <div class="main_content_inner clearfix" :class="{ hasRight: hasWhite }">
        <div class="videoAndDocLocation">
          <p class="timeAndCountInfo">
            <el-popover placement="right" trigger="click">
              <div>
                <p>
                  {{ $t("times") }}:
                  {{ talkInfo.start_time | secondFormat("LLL") }} -
                  {{ talkInfo.end_time | secondFormat("T") }}
                </p>
                <p></p>
                <p class="fl">{{ $t("participant") }}：</p>
                <p class="fl">
                  {{
                    talkInfo.send_user_name_en
                      | priorFormat(talkInfo.send_user_name_zh, LOCALE)
                  }}
                  <br />
                  {{
                    talkInfo.in_user_name_en
                      | priorFormat(talkInfo.in_user_name_zh, LOCALE)
                  }}
                </p>
              </div>
              <i class="el-icon-info cursor" slot="reference"></i>
            </el-popover>
            <span class="timeText">
              <span v-if="chatTimeStatus == 'before'">{{
                $t("chatMeetingTimeBefore", { time: timeDiff })
              }}</span>
              <span v-if="chatTimeStatus == 'ing'">{{
                $t("chatMeeingTimeAfter", { time: timeDiff })
              }}</span>
              <span v-if="chatTimeStatus == 'after'">
                {{ $t("chatMeeingTimeDelay", { time: timeDiff }) }}
              </span>
              <span v-if="chatTimeStatus == 'end'">
                {{ $t("chatMeeingTimeEnd") }}
              </span>
            </span>
            | {{ $t("contPeople", { count: meetingCount }) }}
          </p>
          <div class="videoAndDocInner">
            <div class="videoLocation" :class="{ atRight: hasWhite }">
              <videoChat
                ref="videoChat"
                @selfStatusChange="selfStatusChange"
                @countChange="countChange"
                @userInfoUpdata="handleUserInfoUpdata"
                :channel_id="channel_id"
                :user_id="user_id"
                :smallPanel="hasWhite"
                :mediaType="selfStatus"
                :chatInfoList="chatInfoList"
              ></videoChat>
            </div>
            <div class="whiteLocation">
              <whiteBoard
                ref="whiteBoard"
                :whiteInfo="whiteInfo"
                :user_id="user_id"
                :has_docs="hasWhite"
                @docsUploadSuccess="docsUploadSuccess"
                @docsUploadBegin="docsUploadBegin"
                @docsClearSuccess="docsClearSuccess"
              ></whiteBoard>
            </div>
          </div>
        </div>
      </div>
      <div class="right_video_location" v-if="hasWhite"></div>
      <div class="clearfix"></div>
      <div class="controlsLocation">
        <div class="micControl fl">
          <div
            class="item"
            @click="stopSelfMedia('audio')"
            v-if="selfStatus.audio"
          >
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/micrNo.png"
              alt=""
            />
            <el-button type="text" size="small">{{ $t("closeMIC") }}</el-button>
          </div>
          <div class="item" @click="startSelfMedia('audio')" v-else>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/micr.png"
              alt=""
            />
            <el-button type="text" size="small">{{ $t("openMIC") }}</el-button>
          </div>
        </div>
        <div class="videoControl fl">
          <div
            class="item"
            @click="stopSelfMedia('video')"
            v-if="selfStatus.video"
          >
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/cameraNo.png"
              alt=""
            />
            <el-button type="text" size="small">{{
              $t("closeCamera")
            }}</el-button>
          </div>
          <div class="item" @click="startSelfMedia('video')" v-else>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/camera.png"
              alt=""
            />
            <el-button type="text" size="small">{{
              $t("openCamera")
            }}</el-button>
          </div>
        </div>
        <div class="docControl fl">
          <div class="item" @click="uploadDocs">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/wordUpload.png"
              alt=""
            />
            <el-button type="text" :loading="fileBtnLoading" size="small">{{
              $t("Uploaddocument")
            }}</el-button>
          </div>
        </div>
        <div class="messageControl fl">
          <el-badge :is-dot="hasNewMessage">
            <div class="item" @click="messageClick">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/chat.png"
                alt=""
              />
              <el-button type="text" size="small">{{ $t("chat") }}</el-button>
            </div>
          </el-badge>
        </div>
        <div class="closeControl fr">
          <div class="item quit" @click="closeSDK">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/quit.png"
              alt=""
            />
            <el-button type="text" size="small">{{ $t("quit") }}</el-button>
          </div>
        </div>
        <div class="docPageControl fr" v-if="hasWhite && isDocOwner">
          <div class="item fl" @click="clearWhite">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/empty.png"
              alt=""
            />
            <el-button type="text" size="small">{{ $t("clearDoc") }}</el-button>
          </div>
          <div class="item fl" @click="pptPreviousStep">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/last.png"
              alt=""
            />
            <el-button type="text" size="small">{{
              $t("previousPage")
            }}</el-button>
          </div>
          <div class="item fl" @click="pptNextStep">
            <el-button type="text" size="small">{{ $t("nextPage") }}</el-button>
            <img
              class="atRight"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/next.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="textChatLocation" v-show="showMessage">
        <div class="textInner">
          <textChat
            ref="textChat"
            @hideChatPanel="messageClick"
            :channel_id="channel_id"
            :user_id="user_id"
            @reviceMessage="reviceMessage"
            :chatInfoList="chatInfoList"
          ></textChat>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkDevice from "~/baseComponents/checkDevice";
import videoChat from "./components/videoChat";
import textChat from "./components/textChat";
import whiteBoard from "../biunique/components/whiteBoard";
import { formatSeconds, confirmUserInfo } from "../biunique/utils";

export default {
  components: {
    checkDevice,
    videoChat,
    textChat,
    whiteBoard,
  },
  data() {
    return {
      selfStatus: {
        video: true,
        audio: true,
      },

      meetingCount: 1,
      channel_id: null,
      user_id: null,
      back_url: "",
      startTime: "",
      endTime: "",
      hasConfirmSdk: false,
      timeDiff: "",
      talkInfo: {},
      whiteInfo: {},
      hasWhite: false,
      fileBtnLoading: false,
      showMessage: false,
      hasNewMessage: false,
      chatTimeStatus: "ing",
      isLoading: true,
      showCheck: true,
      chatInfoList: [],
      isCancelMeeting: false,
      userIdisInRoom: false,
      chatStatus: "",
      isDocOwner:false
    };
  },
  mounted() {
    //this.initPageParams();
    this.$refs.checkDevice.stratCheck();
  },
  methods: {
    async initPageParams() {
      this.isLoading = true;
      let routerParams = {};
      if (this.$route.query.parameter) {
        routerParams = this._decode(this.$route.query.parameter);
      }
      let channel_id = routerParams.channel_id || "1594878083000";
      let user_id = routerParams.user_id || 52048;
      let back_url = routerParams.back_url;
      this.channel_id = channel_id;
      this.user_id = parseInt(user_id);
      this.back_url = back_url;
      let uidList = await this.$store.dispatch("baseStore/getChatUidList", {
        live_id: channel_id,
      });
      if (uidList.user_list) {
        if (uidList.user_list.indexOf(user_id) != -1) {
          this.userIdisInRoom = true;
          this.isLoading = false;
          return;
        }
      }
      let talkInfo;
      talkInfo = await this.$store.dispatch("baseStore/getVideoChatInfo", {
        live_id: channel_id,
        isdaily: true,
      });
      this.isCancelMeeting =
        talkInfo.data.status == 4 || talkInfo.data.status == 5;

      if (this.isCancelMeeting) {
        this.isLoading = false;
        return;
      }
      if (this.user_id.toString().length == 8) {
        let res = await this.$store.dispatch("getInviteInfo", {
          live_id: this.channel_id,
        });
        if (res.success) {
          let name = res.data.find((item) => item.identify == this.user_id)
            .nick_name;
          let avatar = res.data.find((item) => item.identify == this.user_id)
            .avatar;
          this.chatInfoList.push({
            id: this.user_id,
            name,
            avatar,
          });
        }
      }
      let info = talkInfo.data;

      this.startTime = info.start_time;
      this.endTime = info.end_time;
      this.talkInfo = Object.assign({}, talkInfo.data);
      this.whiteInfo = {
        uuid: info.white_uuid,
        roomToken: info.white_token,
      };

      this.isLoading = false;

      this.confirmBothInfo(this.talkInfo);
      this.confirmTime();
    },
    confirmBothInfo(info) {
      let bothInfo = confirmUserInfo(info, this.user_id, this.LOCALE);
      this.chatInfoList.push({
        id: bothInfo.selfInfo.id,
        name: bothInfo.selfInfo.name,
        avatar: bothInfo.selfInfo.avatar,
      });
      this.chatInfoList.push({
        id: bothInfo.otherInfo.id,
        name: bothInfo.otherInfo.name,
        avatar: bothInfo.otherInfo.avatar,
      });
    },
    handleUserInfoUpdata(data) {
      if (
        !this.chatInfoList.find((item) => {
          return item.id == data.id;
        })
      ) {
        this.chatInfoList.push(data);
      }
    },
    enterIntoChat(result) {
      this.selfStatus = result;
      this.showCheck = false;
      this.initPageParams();
    },
    confirmTime() {
      // this.startTime = 1622102400;
      //this.endTime = 1622188549;

      let isBetween = this.$moment().isBetween(
        this.$moment(this.startTime * 1000).add("-10", "m"),
        this.$moment(this.endTime * 1000).add("5", "m"),
        "seconds"
      );
      if (isBetween) {
        this.chatTimeStatus = "ing";
        this.confirmSdk();
      }
      let current = this.$moment();
      let startdiff = this.$moment(parseInt(this.startTime * 1000)).diff(
        current,
        "seconds"
      );
      let enddiff = this.$moment(parseInt(this.endTime * 1000)).diff(
        current,
        "seconds"
      );

      if (startdiff > 0) {
        //会议未开始
        if (startdiff > 600) {
          this.chatStatus = "before";
        }
        this.chatTimeStatus = "before";

        let timer_a = setInterval(() => {
          let newCurrent = this.$moment();
          let newDiff = this.$moment(parseInt(this.startTime * 1000)).diff(
            newCurrent,
            "seconds"
          );
          if (newDiff == 600) {
            this.chatTimeStatus = "ing";
            this.confirmSdk();
          }
          this.timeDiff = formatSeconds(newDiff, this.LOCALE);
          if (newDiff <= 0) {
            this.confirmTime(); //开始时间倒计时完成，会议开始
            clearInterval(timer_a);
          }
        }, 1000);
      } else if (startdiff <= 0 && enddiff > 0) {
        //会议进行中
        this.chatTimeStatus = "ing";
        let timer_b = setInterval(() => {
          let newCurrent = this.$moment();
          let newDiff = this.$moment(parseInt(this.endTime * 1000)).diff(
            newCurrent,
            "seconds"
          );
          this.timeDiff = formatSeconds(newDiff, this.LOCALE);
          if (newDiff <= 0) {
            this.confirmTime(); //会议时间倒计时完成，会议结束
            clearInterval(timer_b);
          }
        }, 1000);
      } else if (enddiff <= 0) {
        //会议结束
        this.chatTimeStatus = "after";
        this.delayTime();
      }
    },
    delayTime() {
      let isBetween = this.$moment().isBetween(
        this.$moment(),
        this.$moment(this.endTime * 1000).add("5", "m"),
        "seconds"
      );
      if (isBetween) {
        let nowdiff = this.$moment(parseInt(this.endTime * 1000))
          .add("5", "m")
          .diff(this.$moment(), "seconds");
        let nowtimeTip = formatSeconds(nowdiff, this.LOCALE);
      }
      this.isDelay = true;
      let timer_c = setInterval(() => {
        let newCurrent = this.$moment();
        let newDiff = this.$moment(parseInt(this.endTime * 1000))
          .add("5", "m")
          .diff(newCurrent, "seconds");
        this.timeDiff = formatSeconds(newDiff, this.LOCALE);
        if (newDiff <= 0) {
          this.chatStatus = "end";
          this.chatTimeStatus = "end";
          this.closeSDK(); //会议时间倒计时完成，会议结束
          clearInterval(timer_c);
        }
      }, 1000);
    },
    confirmSdk() {
      if (this.hasConfirmSdk) {
        return;
      }
      this.hasConfirmSdk = true;
      this.$nextTick(() => {
        this.handleLayout();
        this.$refs.videoChat.initVideoChat();
        this.$refs.textChat.initTextChat();
        this.$refs.whiteBoard.initWHITESDK();
      });
    },
    async closeSDK() {
      this.chatStatus = "end"
      this.chatTimeStatus = "end";
      this.clearWhiteLayout();
      if (!this.hasConfirmSdk) {
        return;
      }
      this.$refs.videoChat.closeSdk();
      this.$refs.textChat.closeSdk();
    },
    async clearWhiteLayout() {
      this.hasWhite = false;
      if (this.isDocOwner) {
        let result = await this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: 0,
        });
        this.isDocOwner = false;
      }
    },
    handleLayout() {
      if (this.talkInfo.has_docs) {
        this.hasWhite = true;
        this.videoAtRight = true;
        this.whiteAtRight = false;
        if (this.talkInfo.has_docs == this.user_id) {
          this.isDocOwner = true;
        }
      }
    },
    stopSelfMedia(type) {
      this.$refs.videoChat.stopSelfMedia(type);
    },
    startSelfMedia(type) {
      this.$refs.videoChat.startSelfMedia(type);
    },
    selfStatusChange(status) {
      console.log(status);
      this.selfStatus.video = status.video;
      this.selfStatus.audio = status.audio;
    },
    uploadDocs() {
      if (this.fileBtnLoading) return;
      this.$refs.whiteBoard.handleUploadClick();
    },
    docsUploadSuccess(user_id) {
      this.fileBtnLoading = false;
      this.hasWhite = true;
      this.uploadBtnText = this.$t("Uploaddocument");
      if (user_id == this.user_id) {
        this.isDocOwner = true;
        this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: parseInt(this.user_id),
        });
      } else {
        this.isDocOwner = false;
      }
      this.$refs.whiteBoard.handleWhiteFit();
    },
    docsUploadBegin(user_id) {
      this.fileBtnLoading = true;
      if (user_id != this.user_id) {
        this.uploadBtnText = this.$t("EachUploaddocument");
      } else {
        this.uploadBtnText = this.$t("uploading");
      }
    },
    async docsClearSuccess() {
      this.hasWhite = false;
      if (this.isDocOwner) {
        let result = await this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: 0,
        });
        this.isDocOwner = false;
      }
    },
    pptPreviousStep() {
      if (this.fileBtnLoading) return;
      this.$refs.whiteBoard.pptPreviousStep();
    },
    pptNextStep() {
      if (this.fileBtnLoading) return;
      this.$refs.whiteBoard.pptNextStep();
    },
    clearWhite() {
      if (this.fileBtnLoading) return;
      this.$refs.whiteBoard.clearDocs();
    },
    messageClick() {
      this.hasNewMessage = false;
      this.showMessage = !this.showMessage;
    },
    reviceMessage() {
      if (!this.showMessage) {
        this.hasNewMessage = true;
      }
    },
    countChange(count) {
      this.meetingCount = count;
    },
  },
};
</script>

<style scoped lang="less">
@import "./pc.less";
</style>